<template>
  <div class="js-range-slider-wrap">
    <input type="range" :id="id" class="js-range-slider">
  </div>
</template>

<script>
//import $ from 'jquery'
import 'ion-rangeslider'

export default {
  name: 'RangeSlider',
  props: {
    id: {
      type: String,
      require: true,
      default: ''
    },
    type: {
      type: String,
      require: false,
      default: 'single'
    },
    min: {
      type: Number,
      require: false,
      default: 0
    },
    max: {
      type: Number,
      require: false,
      default: 100
    },
    from: {
      type: Number,
      require: false,
      default: 0
    },
    to: {
      type: Number,
      require: false,
      default: 100
    },
    step: {
      type: Number,
      require: false,
      default: 1,
    },
    values: [],
    keyboard: {
      type: Boolean,
      require: false,
      default: true
    },
    grid: {
      type: Boolean,
      require: false,
      default: false
    },
    grid_margin: {
      type: Boolean,
      require: false,
      default: true
    },
    grid_num: {
      type: Number,
      require: false,
      default: 4
    },
    grid_snap: {
      type: Boolean,
      require: false,
      default: false
    },
    drag_interval: {
      type: Boolean,
      require: false,
      default: false
    },
    min_interval: {
      type: Number,
      require: false,
    },
    max_interval: {
      type: Number,
      require: false,
    },
    from_fixed: {
      type: Boolean,
      require: false,
      default: false
    },
    from_min: {
      type: Number,
      require: false,
      default: 0
    },
    from_max: {
      type: Number,
      require: false,
      default: 100
    },
    from_shadow: {
      type: Boolean,
      require: false,
      default: false
    },
    to_fixed: {
      type: Boolean,
      require: false,
      default: false
    },
    to_min: {
      type: Number,
      require: false,
      default: 0
    },
    to_max: {
      type: Number,
      require: false,
      default: 100
    },
    to_shadow: {
      type: Boolean,
      require: false,
      default: false
    },
    skin: {
      type: String,
      require: false,
      default: 'flat'
    },
    hide_min_max: {
      type: Boolean,
      require: false,
      default: false
    },
    hide_from_to: {
      type: Boolean,
      require: false,
      default: false
    },
    force_edges: {
      type: Boolean,
      require: false,
      default: false
    },
    extra_classes: {
      type: String,
      require: false,
    },
    block: {
      type: Boolean,
      require: false,
      default: false
    },
    prettify_enabled: {
      type: Boolean,
      require: false,
      default: true
    },
    prettify_separator: {
      type: String,
      require: false,
      default: ' '
    },
    prettify: {
      type: Function,
      require: false,
      default: null
    },
    prefix: {
      type: String,
      require: false,
    },
    postfix: {
      type: String,
      require: false,
    },
    max_postfix: {
      type: String,
      require: false,
    },
    decorate_both: {
      type: Boolean,
      require: false,
      default: true
    },
    values_separator: {
      type: String,
      require: false,
      default: ' — '
    },
    input_values_separator: {
      type: String,
      require: false,
      default: ' ; '
    },
    disable: {
      type: Boolean,
      require: false,
      default: false
    },
    scope: {
      type: Object,
      require: false,
      default: null
    },
    // onStart: {
    //   type: Function,
    //   require: false,
    //   default: null
    // },
    // onChange: {
    //   type: Function,
    //   require: false,
    //   default: null
    // },
    // onFinish: {
    //   type: Function,
    //   require: false,
    //   default: null
    // },
    // onUpdate: {
    //   type: Function,
    //   require: false,
    //   default: null
    // }
  },
  data() {
    return {
      value: 0,
    }
  },
  mounted() {
    let self = this
    
    window.jQuery("#" + this.id).ionRangeSlider({
      type: this.type,
      min: this.min,
      max: this.max,
      from: this.from,
      to: this.to,
      step: this.step,
      values: this.values,
      keyboard: this.keyboard,
      grid: this.grid,
      grid_margin: this.grid_margin,
      grid_num: this.grid_num,
      grid_snap: this.grid_snap,
      drag_interval: this.drag_interval,
      min_interval: this.min_interval,
      max_interval: this.max_interval,
      from_fixed: this.from_fixed,
      from_min: this.from_min,
      from_max: this.from_max,
      from_shadow: this.from_shadow,
      to_fixed: this.to_fixed,
      to_min: this.to_min,
      to_max: this.to_max,
      to_shadow: this.to_shadow,
      skin: this.skin,
      hide_min_max: this.hide_min_max,
      hide_from_to: this.hide_from_to,
      force_edges: this.force_edges,
      extra_classes: this.extra_classes,
      block: this.block,
      prettify_enabled: this.prettify_enabled,
      prettify_separator: this.prettify_separator,
      prettify: this.prettify,
      prefix: this.prefix,
      postfix: this.postfix,
      max_postfix: this.max_postfix,
      decorate_both: this.decorate_both,
      values_separator: this.values_separator,
      input_values_separator: this.input_values_separator,
      disable: this.disable,
      scope: this.scope,
      // onStart: this.onStart,
      // onChange: this.onChange,
      // onFinish: this.onFinish,
      // onUpdate: this.onUpdate,
      onStart: function(data) {
        // fromがnullで来た場合0にして返す
        if(data.from == 0) {
          self.$emit('updateRange', { 'id': self.id, 'data': data })
        }
      },
      onFinish: function(data) {
        self.$emit('updateRange', { 'id': self.id, 'data': data })
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.irs-hidden-input {
  opacity: 0;
}
</style>